// app.js

import $ from 'jquery';
import 'what-input';
import 'imagesloaded';

import Util from './module/Util';
const util = new Util();

import Menu from './module/Menu';
new Menu();

import Pulldown from './module/Pulldown';
new Pulldown();

import Home from './page/Home';
import Case from './page/Case';
import Staff from './page/Staff';

util.scroll();

$(function(){
  new Home();
  new Case();
  new Staff();

  $('#zip').on('keyup', function(){
    AjaxZip3.zip2addr(this,'','pref','addr');
  });
  $('.contact-form-submit a').on('click', function(e){
    e.preventDefault();
    $('.wpcf7-form').submit();
  });
});