// Util

'use strict'

export default class {
  constructor(){

    window.onload = function(){
      document.body.classList.add('page--show');
    }

    setTimeout(function(){
      document.body.classList.add('page--show');
    }, 5000);

    this.transitionEndEvents = [
      "webkitTransitionEnd",
      "mozTransitionEnd",
      "oTransitionEnd",
      "transitionend"
    ];
    this.transitionEnd = this.transitionEndEvents.join(" ");

    this.animationEndEvents = [
      "webkitAnimationEnd",
      "mozAnimationEnd",
      "oAnimationEnd",
      "animationend"
    ];
    this.animationEnd = this.animationEndEvents.join(" ");

    this.is = {};

    this.getIs();
  }

  getIs(){
    this.is.touchDevice = ('ontouchstart' in window || navigator.msPointerEnabled) ? true : false;
    this.is.sp_s = window.matchMedia('(max-width:320px)').matches;
    this.is.sp_m = window.matchMedia('(max-width:480px)').matches;
    this.is.sp_l = window.matchMedia('(max-width:640px)').matches;
    this.is.tab_p = window.matchMedia('(max-width:768px)').matches;
    this.is.tab_l = window.matchMedia('(max-width:1040px)').matches;
    this.is.xl = window.matchMedia('(max-width:1280px)').matches;
  }

  scroll(){
    let elems = document.querySelectorAll('.js-smooth-scroll');
    [...elems].forEach(elem => {
      elem.addEventListener('click', e => {
        e.preventDefault();

        const target = e.target.dataset.to || '#top';
        const targetElement = document.querySelector(target);

        const rectTop = targetElement.getBoundingClientRect().top
        const offsetTop = window.pageYOffset
        const buffer = document.getElementById('Header').clientHeight;
        const top = rectTop + offsetTop - buffer
        
        window.scrollTo({
          top,
          behavior: "smooth"
        });
      })
    })
  }

  random(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  /*
   * Usage 
   * util.getCookie('accessed')
   */
  getCookie(key){
    var ck = {};
    var key = key;
    var r = document.cookie.split(';');

    r.forEach(function(value) {
      var content = value.split('=');
      var key = content[0];
      var value = content[1];
      ck[key.trim()] = value;
    });

    if( ck[key] ){
      return ck[key];
    }
    else {
      return false;
    }
  }

  /*
   * Usage 
   * util.setCookie('accessed', 'fish', '/', '', '', '');
   */
  setCookie(key, value, path, domain, maxAge, expires){
    var ckstr = "";

    if( key && value ){
      ckstr += key+"="+encodeURIComponent(value);
    }

    if( path ){
      ckstr += "; path="+path;
    }

    if( domain ){
      ckstr += "; domain='"+domain+"'";
    }

    if( maxAge ){
      ckstr += "; max-age="+maxAge;
    }

    if( expires ){
      // expires = new Date(expires);
      ckstr += "; expires="+expires;
    }

    document.cookie = ckstr;
  }

}