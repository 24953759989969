// Home

'use strict'

import $ from 'jquery';
import 'slick-carousel';

export default class {
  constructor(){
    this.$slider = $('.slick-staff');

    this.slider();
  }

  slider(){
    this.$slider.slick({
			slidesToShow: 5,
      slidesToScroll: 5,
      arrows: true,
			dots: true,
			autoplay: true,
			autoplaySpeed: 4000,
			speed: 800,
			responsive: [
				{
					breakpoint: 640,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						dots: true
					}
				}
			]
		})
  }
}