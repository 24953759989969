// Pulldown

'use strict'

import $ from 'jquery';

import Util from './Util';
let util = new Util();

export default class {
  constructor(){
    var me = this;
    
    me.$pulldown = $('.cPulldown');
    me.$list = $('.cPulldownContent');

    me.$pulldown.each(function(index){
      var $this = $(this);
      var $btn = $this.find('.cPulldownBtn i');

      $btn.on('click', function(e){
        e.preventDefault();
        if( !$this.hasClass('show') ){
          $this.addClass('show');
        }
        else {
          $this.removeClass('show');
        }
      });

    });
  }
}