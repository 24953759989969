// Home

'use strict'

import $ from 'jquery';
import 'slick-carousel';

export default class {
  constructor(){
    this.$cGalleryImages = $('.cGalleryImages');
    this.$cGalleryThumbnails = $('.cGalleryThumbnails');

    this.slider();
  }

  slider(){
    this.$cGalleryImages.slick({
      speed: 800,
      fade: true,
      dots: false,
      asNavFor: '.cGalleryThumbnails'
    });

    this.$cGalleryThumbnails.slick({
      asNavFor: '.cGalleryImages',
      slidesToShow: 8,
      centerPadding: '20px',
      focusOnSelect: true
    })
  }
}