// Home

'use strict'

import $ from 'jquery';
import 'slick-carousel';

export default class {
  constructor(){
    this.$HomeMainvisualImage = $('.HomeMainvisualImage');
    this.$CaseStudyList = $('.CaseStudyList');

    this.slider();
  }

  slider(){
    this.$HomeMainvisualImage.slick({
      autoplay: true,
      speed: 1200,
      fade: true,
      dots: true,
      arrows: false
    });

    this.$CaseStudyList.slick({
      arrow: true,
      slidesToShow: 2,
      centerPadding: "80px",
      responsive: [
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            centerPadding: "40px",
          }
        }
      ]
    })
  }
}